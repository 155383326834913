<template>
  <div>
    <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  props: ["pdf"],
  components: { pdf },
  data: function() {
    return {
      src: pdf.createLoadingTask(this.pdf),
      numPages: undefined,
    };
  },
  mounted: function() {
    this.src.promise.then(
      function(pdf) {
        this.numPages = pdf.numPages;
      }.bind(this)
    );
  },
};
</script>
